import { useRouter } from "next/router";
import { createContext, ReactNode, useContext, useEffect, useState } from "react";

import { getLanguageByCountry, getValidLanguageFromISOLocale } from "@/client/utils";
import { Country, Language } from "@/components/Types";
import { useAuth } from "@/hooks/useAuth";
import messages from "@/translations/translations.csv";

const Context = createContext<{
  selectedLanguage: Language;
  setSelectedLanguage: (language: Language) => void;
  translations: { [key: string]: string };
}>({
  selectedLanguage: "en",
  setSelectedLanguage: () => undefined,
  translations: {},
});

const useDefaultLanguage = (): Language => {
  const router = useRouter();

  const selectedLanguageStoredInLocalStorage = getValidLanguageFromISOLocale(
    typeof localStorage !== "undefined" ? localStorage.getItem("lang") : undefined
  );

  const preferredLanguageFromBrowserSettings = getValidLanguageFromISOLocale(
    typeof navigator === "undefined" ? null : navigator.language
  );

  const currentAppCountry = router.query.country as Country;
  const defaultLanguageForCurrentCountry = getLanguageByCountry(currentAppCountry);

  const result =
    selectedLanguageStoredInLocalStorage ||
    preferredLanguageFromBrowserSettings ||
    defaultLanguageForCurrentCountry ||
    "en";

  return result as Language;
};

export const SelectedLanguageProvider = ({ children }: { children: ReactNode }) => {
  const defaultLanguage = useDefaultLanguage();
  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage);

  // get user language selection from auth session as the highest priority
  const auth = useAuth();
  useEffect(() => {
    if (auth.user?.lang) {
      setSelectedLanguage(auth.user?.lang);
    }
  }, [auth.user?.lang, auth.isLoggedIn]);

  // support anonymous users (who dont have a lang saved in the auth sessios) using localStorage
  useEffect(() => {
    localStorage.setItem("lang", selectedLanguage);
  }, [selectedLanguage]);

  const translations = (() => {
    if (process.env.NEXT_PUBLIC_INTL) {
      // when the app is started with `yarn dev:intl
      return JSON.parse(process.env.NEXT_PUBLIC_INTL);
    }

    switch (selectedLanguage) {
      case "hr":
        return Object.fromEntries(messages.map(({ key, HR }) => [String(key), HR]));
      case "pt":
        return Object.fromEntries(messages.map(({ key, PT }) => [String(key), PT]));
      case "ro":
        return Object.fromEntries(messages.map(({ key, RO }) => [String(key), RO]));
      case "es":
        return Object.fromEntries(messages.map(({ key, ES }) => [String(key), ES]));
      default:
        return {};
    }
  })();

  return (
    <Context.Provider value={{ selectedLanguage, setSelectedLanguage, translations }}>
      {children}
    </Context.Provider>
  );
};

export const useSelectedLanguage = () => useContext(Context);
