import moment from "moment";
import { QueryClient } from "react-query";

import { Language } from "@/components/Types";

export type Environment = "production" | "demo" | "local";

export const getEnvironment: () => Environment = () => {
  const env = typeof window === "undefined" ? "localhost" : window?.location?.hostname;

  switch (env) {
    case "suppliers.fonoa.com":
      return "production";
    case "suppliers-test.fonoa.com":
      return "demo";
    default:
      return "local";
  }
};

// No operation fn
export const noop = () => ({});

export const isApiSupported = (api: string): boolean =>
  typeof window !== "undefined" ? api in window : false;

export const isBrowser = typeof window !== "undefined";

export const localStorageKeys = {
  access_token: "access_token",
  id_token: "id_token",
  expires_at: "expires_at",
  profile: "profile",
};

export const BREAKPOINTS = {
  DESKTOP: "(min-width: 1024px)",
};

export const isSupportedCountry = (countryCode: string) => ["hr", "pt"].includes(countryCode);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const getCountryByLanguage = (language: Language | undefined): string => {
  switch (language) {
    case "hr":
      return "hr";
    case "pt":
      return "pt";
    default:
      return "gb";
  }
};

export const getLanguageByCountry = (country: string | undefined): Language => {
  switch (country) {
    case "hr":
      return "hr";
    case "pt":
      return "pt";
    case "ro":
      return "ro";
    case "ar":
      return "es";
    default:
      return "en";
  }
};

export const getValidLanguageFromISOLocale = (
  isoLocale: string | null | undefined
): Language | null => {
  if (!isoLocale) {
    return null;
  }

  if (["en", "hr", "pt", "ro"].includes(isoLocale)) {
    return isoLocale as Language;
  }

  const twoLetterLanguage = isoLocale.split("-")[0];
  if (["en", "hr", "pt"].includes(twoLetterLanguage)) {
    return twoLetterLanguage as Language;
  }

  return null;
};

export const cleanupQueryParams = (query) => {
  const cleanedQuery = { ...query };

  for (const filter in cleanedQuery) {
    if (!cleanedQuery[filter]) {
      delete cleanedQuery[filter];
    }
  }

  return cleanedQuery;
};

export const isFullYear = (from: string, to: string): boolean => {
  if (!from || !to) return false;

  // We use startOf('d') because we ignore anything more granular than days

  const fromIsYearStart = moment.utc(from).startOf("d").isSame(moment.utc(from).startOf("y"));
  const toIsYearEnd = moment.utc(to).endOf("d").isSame(moment.utc(to).endOf("y"));
  const rangeIsOneYear = moment
    .utc(to)
    .startOf("d")
    .add(1, "d")
    .subtract(1, "y")
    .isSame(moment.utc(from).startOf("d"));

  if (fromIsYearStart && toIsYearEnd && rangeIsOneYear) {
    return true;
  }

  return false;
};
